
document.addEventListener('DOMContentLoaded', () => {
  imaskMascaras()
})


function imaskMascaras() {
  'use strict'

  const $mascaraCartaoNumero = document.querySelectorAll('.js-imask-card-numero')
  const $mascaraCartaoCSC = document.querySelectorAll('.js-imask-card-csc')
  const $mascaraCartaoValidade = document.querySelectorAll('.js-imask-card-validade')
  const $mascaraCartaoValidadePagarme5 = document.querySelectorAll('.js-imask-card-validade-pagarme5')
  const $mascaraCartaoTel = document.querySelectorAll('.js-imask-card-tel')
  const $mascaraCEP = document.querySelectorAll('.js-imask-cep')
  const $mascaraCPF = document.querySelectorAll('.js-imask-cpf')
  const $mascaraCNPJ = document.querySelectorAll('.js-imask-cnpj')
  const $mascaraTel = document.querySelectorAll('.js-imask-tel')
  const $mascaraNumero = document.querySelectorAll('.js-imask-numero')
  const $mascaraMoeda = document.querySelectorAll('.js-imask-moeda')
  const $mascaraNascimento = document.querySelectorAll('.js-imask-nascimento')

  // aplicacao do IMask
  $mascaraCartaoNumero.forEach($campo => {
    mascara($campo, '#### #### #### ####')
  })

  $mascaraCartaoCSC.forEach($campo => {
    mascara($campo, '####')
  })

  $mascaraCartaoValidade.forEach($campo => {
    mascara($campo, '##/##')
  })

  $mascaraCartaoValidadePagarme5.forEach($campo => {
    mascara($campo, '##-##')
  })

  $mascaraCartaoTel.forEach($campo => {
    mascara($campo, '(##) #####-####')
  })

  $mascaraCEP.forEach($campo => {
    mascara($campo, '#####-###')
  })

  $mascaraCPF.forEach($campo => {
    mascara($campo, '###.###.###-##')
  })

  $mascaraCNPJ.forEach($campo => {
    mascara($campo, '##.###.###/####-##')
  })

  $mascaraTel.forEach($campo => {
    mascara($campo, '(##) #####-####')
  })

  $mascaraNumero.forEach($campo => {
    mascaraDecimal($campo, 0)
  })

  $mascaraMoeda.forEach($campo => {
    mascaraDecimal($campo, 2)
  })

  $mascaraNascimento.forEach($campo => {
    mascara($campo, '##/##/####')
  })
}